



































































































































































import { Component, Vue, PropSync } from "vue-property-decorator";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsPaciente } from "@/utils/utils-paciente";
import entrevistaModule from "@/store/modules/entrevista-module";
import { dietaDto } from "@/shared/dtos/visualizacion_dieta/dietaDto";
import { UtilsDate } from "@/utils/utils-date";
import ConfiguracionModule from "@/store/modules/Configuracion-module";
@Component({})
export default class dialog_datos_ultima_consulta extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("id_paciente", { type: Number }) syncedId_Paciente!: number;
  @PropSync("id_dieta", { type: Number }) syncedId_Dieta!: number;

  created() {
    entrevistaModule.getultimaentrevista(this.syncedId_Paciente);
  }
  public get datos() {
    return entrevistaModule.entrevista;
  }

  public close() {
    this.$emit("close");
    this.syncedDialog = false;
  }
}
